import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoPlayer from "./VideoPlayer";

function SampleNextArrow3(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/nextarrow.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "80px",
        height: "40px",
        // top: "auto",
        // left: "10%",
        // bottom: "0",
        right: "45%",
        bottom: "-15%",
        top: "auto",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow3(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/prevarrow.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "80px",
        height: "40px",
        // position: "absolute",
        top: "auto",
        // left: "0",
        bottom: "-15%",
        left: "43%",
        zIndex: "999",
      }}
      onClick={onClick}
    />
  );
}

function GalleryPopup(props) {
  var gallery = {
    className: "center",
    centerMode: true,
    initialSlide: props.imageindex,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    autoplay: false,
    nextArrow: <SampleNextArrow3 />,
    prevArrow: <SamplePrevArrow3 />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          className: "vertical-slider",
          vertical: true,
          centerMode: false,
          verticalSwiping: true,
          infinite: true,
          slidesToShow: 3,
          speed: 500,
          slidesToScroll: 1,
          arrows: false,
          autoplay: false,
        },
      },
    ],
  };
  return (
    <>
      <div className="gallry_bg">
        <img
          src="./assets/images/cross.svg"
          className="close_gallery"
          alt=""
          onClick={props.onClose}
        />
        <div className="gallery_content">
          {/* <Slider {...gallery}> */}
          {/* <div>
                            <div className="imgpopup_padding">
                                <img src= {props.imageindex} className="img-fluid gallery_img" alt=""  />
                            </div>
                        </div> */}
          {/* {Object.keys(props.items).length > 0 &&
              props.items &&
              props.items.map((item, index) => {
                return (
                  <div key={index}> */}
          <div className="imgpopup_padding">
            {/* <img src={imageitem} className="img-fluid gallery_img" alt="" /> */}


            <iframe className="desk_frame"
              src={props.youtubeUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />


            {/* <div>
              <VideoPlayer
                autoplay={true}
                onReady={props.onReady}
                id={"test"}
                keyi={"test"}
                key={"test"}
                poster={props.poster}
                videoUrl={props.videoUrl}
              />
            </div> */}
          </div>
          {/* </div> */}
          {/* );
              })} */}

          {/* <div>
                            <div className="imgpopup_padding">
                                <img src="https://media.istockphoto.com/photos/mountain-landscape-picture-id517188688?k=20&m=517188688&s=612x612&w=0&h=i38qBm2P-6V4vZVEaMy_TaTEaoCMkYhvLCysE7yJQ5Q=" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div>


                        <div>
                            <div className="imgpopup_padding">
                                <img src="https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div>

                        <div>
                            <div className="imgpopup_padding">
                                <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div>

                        <div>
                            <div className="imgpopup_padding">
                                <img src="./assets/images/img-17.jpg" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div>

                        <div>
                            <div className="imgpopup_padding">
                                <img src="./assets/images/img-17.jpg" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div>


                        <div>
                            <div className="imgpopup_padding">
                                <img src="./assets/images/img-17.jpg" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div>

                        <div>
                            <div className="imgpopup_padding">
                                <img src="./assets/images/img-17.jpg" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div>

                        <div>
                            <div className="imgpopup_padding">
                                <img src="./assets/images/img-17.jpg" className="img-fluid gallery_img" alt="" />
                            </div>
                        </div> */}
          {/* </Slider> */}
        </div>
      </div>
    </>
  );
}

export default GalleryPopup;
