import React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import $ from 'jquery';
// import { getParams, onCTEventCall } from "../utils/helper";
// Initialize the Chromecast plugin
// require('@silvermine/videojs-chromecast')(videojs);
// require("videojs-contrib-hls");

export const VideoJS = (props) => {

    const videoJsOptions = { // lookup the options in the docs for more options
        autoplay: props.autoplay,
        controls: true,
        responsive: true,
        fluid: true,
        muted: false,
        cors: 'anonymous',
        playsinline: true,
        crossorigin: "anonymous",
        // preload: 'metadata',
        preload: 'none',
        poster: props.poster,
        controls: true,
        sources: [{
            // src: "https://appvideos.narendramodi.in/v2/2333/55080/68697/2295211_renditions/hls/2295211_864.m3u8",
            // src: "https://vz-b2cb11d7-982.b-cdn.net/1ead0f8b-f71b-45a8-b5ba-0267f02ee2f9/playlist.m3u8",
            src: props.videoUrl,
            // src: props.video_url ? props.video_url.split("_864").join("") : '',
            type: 'application/x-mpegURL',
            // src: "https://d29pqav9qrk2pj.cloudfront.net/2021/11/1/1638337334492-vm-Sahayatri.mp4",
            // type: 'video/mp4'
        }]
    }

    const videoRef = React.useRef();
    const playerRef = React.useRef();
    const { onReady, id } = props;

    React.useEffect(() => {
        // make sure Video.js player is only initialized once
        // console.log('kjhj', videoRef);
        if (videoRef.current) {


            if (!playerRef.current) {
                const videoElement = videoRef.current;
                // console.log('hjghj', videoElement);
                if (!videoElement) return;
                const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
                    // onReady && onReady(player, props.keyi);
                    onReady && onReady(player);
                });
            } else {
                // you can update player here [update player through props]
                const player = playerRef.current;
                player.autoplay(videoJsOptions.autoplay);
                player.muted(videoJsOptions.muted);
                player.src(videoJsOptions.sources);
                player.poster(videoJsOptions.poster);
                // player.chromecast();
            }
        }
    }, [videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    // React.useEffect(() => {
    //     const player = playerRef.current;
    //     return () => {
    //         if (player) {
    //             player.dispose();
    //             playerRef.current = null;
    //             videoRef.current = null;
    //         }
    //     };
    // }, [playerRef]);

    const playPause = (e) => {
        // let params = getParams();
        let video = videoRef.current;
        if (video.paused()) {
            // $(!e ? ".play_icon" : e.target).attr("src", "/assets/images/pause.svg");
            video.play();
            // $(".play_icon").hide();
        } else {
            video.pause();
            // $(!e ? ".play_icon" : e.target).attr("src", "/assets/images/play2.svg");
            // $(".play_icon").show();
        }
    }

    // const muteUnmute = () => {
    //     let params = getParams();
    //     var video = document.getElementById("myVideo_html5_api");
    //     video.muted = !video.muted;
    //     $("#playpause").hide();
    //     onCTEventCall("MuteUnmuteVideo", {
    //         "video": params.url,
    //         "value": "unmute",
    //     });
    // }
    let vid = "myVideo_" + id;

    return (

        <video ref={videoRef} className="video-js vjs-default-skin" id={vid} key={props.keyi} />

        // <div>
        //     {/* <div data-vjs-player style={{ height: "100%" }}> */}
        //     <video ref={videoRef} className="video-js vjs-default-skin" id="myVideo" />
        //     {/* </div> */}
        //     {/* <img
        //         src="/assets/images/play2.svg"
        //         className="img-fluid play_icon"
        //         alt=""
        //         id="playpause"
        //         onClick={(e) => playPause(e)}
        //     /> */}
        // </div>
    );
}

export default React.memo(VideoJS);