import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import GalleryPopup from "./GalleryPopup";
import VideoPopUp from "./VideoPopUp";
import VideoPlayer from "./VideoPlayer";
import $ from "jquery";
import ImagePopUp from "./ImagePopUp";

const msg =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum venenatis pulvinar. Proin vitae lectus urna. Sed erat ipsum, maximus a elit nec, condimentum placerat ex. Ut tincidunt mi eget condimentum mollis. Pellentesque aliquam velit quis est varius, sed molestie dolor ultrices. Pellentesque eget dapibus eros, at blandit arcu. Duis id purus quis mi porttitor viverra vel tempus elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos posuere";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/next.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "30px",
        height: "30px",
        right: "5%",
        bottom: "0",
        top: "auto",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/prev.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "30px",
        height: "30px",
        position: "absolute",
        right: "10%",
        left: "auto",
        bottom: "0",
        top: "auto",
        zIndex: "999",
      }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/nextarrow.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "80px",
        height: "40px",
        right: "0%",
        bottom: "-0%",
        top: "auto",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow2(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/prevarrow.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "80px",
        height: "40px",
        position: "absolute",
        right: "9%",
        left: "auto",
        bottom: "0%",
        top: "auto",
        zIndex: "999",
      }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow3(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/nextarrow.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "80px",
        height: "40px",
        top: "auto",
        left: "9%",
        bottom: "0",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow3(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "url('../assets/images/prevarrow.svg') no-repeat",
        backgroundSize: "100% 100%",
        width: "80px",
        height: "40px",
        position: "absolute",
        top: "auto",
        left: "0",
        bottom: "0",
        zIndex: "999",
      }}
      onClick={onClick}
    />
  );
}

function LandingPage(props) {
  const [fulldata, setFullData] = useState({});
  const [showGallery, setShowGallery] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [indeex, setIndeex] = useState("");
  const [imageindeex, setImageIndeex] = useState("");
  const [isReadMore, setIsReadMore] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  //   const [poster, setPoster] = useState("");
  //   const [videoUrl, setVideUrl] = useState("");
  const [videoObject, setVideoObject] = useState({});

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const getData = async () => {
    const res = await axios.get("data.json");
    // console.log(
    //   "getdata res ",
    //   res.data + " " + res.data.title + " - " + res.data.aboutthebook.image
    // );
    setFullData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  var reviewslider = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  var authorslider = {
    dots: false,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow3 />,
    prevArrow: <SamplePrevArrow3 />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  var buynow = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4.3,
    speed: 500,
    autoplay: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          className: "",
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const handlePlayerReady = (player) => {
    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });
    player.on("play", () => {
      try {
        window.gtag("event", "video_play_clicked", {
          platform: getDeviceType(),
          source: window.location.href,
        });
      } catch (error) { }
    });
    player.on("pause", () => {
      try {
        window.gtag("event", "video_pause_clicked", {
          platform: getDeviceType(),
          source: window.location.href,
        });
      } catch (error) { }
    });
    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };
  const getDeviceType = () => {
    try {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
      }
      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return "mobile";
      }
    } catch (er) {
      return "desktop";
    }
    return "desktop";
  };

  const setBuyNoweEvent = () => {
    try {
      window.gtag("event", "buy_now_clicked", {
        platform: getDeviceType(),
        source: window.location.href,
      });
    } catch (error) { }
  };

  const gtagEventForAuthVid = (author_name) => {

    // console.log(author_name, 'author_nameauthor_name');

    try {
      window.gtag("event", author_name + "_Video_Play", {
        platform: getDeviceType(),
        source: window.location.href,
      });
    } catch (error) { }

  }
  const gtagCloseAuth_vid = (author_name) => {
    // console.log(author_name, 'author_nameauthor_name');

    try {
      window.gtag("event", author_name + "_Video_Close", {
        platform: getDeviceType(),
        source: window.location.href,
      });
    } catch (error) { }

  }

  return (
    <>
      <nav id="myScrollspy" className="fixed-top navbar-expand-sm navbar_width">
        <div className="container-fluid">
          <a href="#" className="logo">
            <img src="./assets/images/logo.png" alt="" />
          </a>
          <button
            className="navbar-toggler custom_btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="toggle_img"></span>
          </button>
          <div
            className="collapse navbar-collapse navbar_popup"
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav custom_v_navbar">
              <li className="nav-item">
                <a className="nav-link active" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#book">
                  About the book
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#author">
                  About the authors
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#launch">
                  From the authors
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#booklaunch">
                Book Launch
                </a>
              </li>
              {/* <li className="nav-item">
                                <a className="nav-link" href="#review">Reviewers</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#media">Media</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#launch">Book Launch</a>
                            </li> */}
              <li
                className="nav-item"
                onClick={() => {
                  setBuyNoweEvent();
                }}
              >
                <a
                  target="_blank"
                  href="https://t.co/mgbsoU125t"
                  className="nav-link"
                >
                  Buy Now
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* <nav className="fixed-top navbar-expand-sm navbar_width">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Logo</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Link</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav> */}
      <section id="home">
        <div className="container">
          <div className="baneer_list">
            <ul>
              <li>
                <h2>
                  {fulldata.title}
                  <span>{fulldata.tag}</span>
                </h2>

                {isMobile && isReadMore ? (
                  <p>
                    {Object.keys(fulldata).length > 0 &&
                      fulldata.description.slice(0, 150) + "..."}
                  </p>
                ) : (
                  <p>{fulldata.description}</p>
                )}
                <span onClick={toggleReadMore} className="read-or-hide">
                  {isMobile && isReadMore ? (
                    <button type="button">read more</button>
                  ) : (
                    <button type="button">read less</button>
                  )}

                  {/* {isReadMore ? text.slice(0, 150) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"} */}
                </span>
                {/* <p>{fulldata.description}</p> */}
                {/* <ReactReadMoreReadLess
                                    charLimit={200}
                                    readMoreText={"...Read more"}
                                    readLessText={"Read less"}
                                    readMoreClassName="read-more-less--more"
                                    readLessClassName="read-more-less--less"
                                >
                                    {fulldata.description}
                                </ReactReadMoreReadLess> */}
                {/* <button type="button">read more</button> */}
              </li>
              <li>
                {/* <img src="./assets/images/img-1.jpg" className="img-fluid" alt="" /> */}
                <img src={fulldata.image} className="img-fluid" alt="" />
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section id="book" className="about_padding">
        <div className="container">
          <div className="about_width">
            <div className="about_head">
              <h5>About the book</h5>
            </div>

            <div className="about_detail">
              {/* <h3>Describe as the • “Prime minister of India”, • HIS BOOK HAVE SOLD • Describe as the • </h3> */}
              <h3
                dangerouslySetInnerHTML={{
                  __html:
                    Object.keys(fulldata).length > 0 &&
                    fulldata.aboutthebook.highlights,
                }}
              ></h3>
              {/* <img src={Object.keys(fulldata).length > 0 && fulldata.aboutthebook.image} className="img-fluid" alt="" /> */}
              {Object.keys(fulldata).length > 0 && (
                <div>
                  <VideoPlayer
                    autoplay={false}
                    onReady={handlePlayerReady}
                    id={"test"}
                    keyi={"test"}
                    key={"test"}
                    poster={fulldata.aboutthebook.poster}
                    videoUrl={fulldata.aboutthebook.videoUrl}
                  />
                </div>
              )}
              {/* <h3>“Prime minister of India”, • HIS BOOK HAVE SOLD • <span>Adapted into films,</span> Television series miniseries, <span>And comic books. 63 Novels,</span> • He has also written approximately 200 short stories,</h3> */}
              <h3
                style={{ marginTop: "10px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    Object.keys(fulldata).length > 0 &&
                    fulldata.aboutthebook.description,
                }}
              ></h3>
            </div>
          </div>
        </div>
      </section>

      {fulldata &&
        Object.keys(fulldata).length > 0 &&
        fulldata.abouttheauthors && (
          <section id="author" className="author_padding">
            <div className="container">
              <div className="about_width">
                <div className="about_head">
                  <h5>About the authors</h5>
                </div>

                <div className="author_slider">
                  <Slider {...authorslider}>
                    {fulldata.abouttheauthors.map((imageitem, index) => {
                      return (
                        <div key={index}>
                          <div className="mobile_flex">
                            <p>{imageitem.description}</p>
                            <div className="author_card">
                              <img
                                src={imageitem.image}
                                className="author_img"
                                alt=""
                              />
                              <div className="auth_detail">
                                <h3>
                                  {Object.keys(fulldata).length > 0 &&
                                    imageitem.author}
                                </h3>
                                {/* <div className="auth_div"> */}
                                {/* <h6><img src={Object.keys(fulldata).length > 0 && imageitem.image} alt="" /> {imageitem.title}</h6> */}
                                {/* <h5>{imageitem.headings}</h5> */}
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        )}
      {/* {fulldata && Object.keys(fulldata).length > 0 && fulldata.bookreviews &&

                <section id="review" className="review_padding">
                    <div className="container">
                        <div className="about_width">
                            <div className="about_head">
                                <h5>Book Reviews</h5>
                            </div>

                            <div className="review_slider">
                                <Slider {...reviewslider}>
                                    {fulldata.bookreviews.map((reviewitem, index) => {
                                        return (
                                            <div>
                                                <div className="review_detail">
                                                    <h4><span className="review_name">Nirav Shah</span> <span className="review_line"></span>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur</h4>
                                                    <ul className="review_social_list d-flex">
                                                        <li>
                                                            <a onClick={() => setShowVideo(true)} href="#">
                                                                <img src="./assets/images/camera.svg" alt="" /> watch video
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#">
                                                                <img src="./assets/images/twitter.svg" alt="" /> tweet
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>)
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            } */}
      {/* <section id="media" className="media_padding">
                <div className="container">
                    <div className="about_width">
                        <div className="about_head">
                            <h5>Media</h5>
                        </div>

                        <div className="media_detail">
                            <img src="./assets/images/img-8.jpg" className="img-fluid desk_media" alt="" />
                            <img src="./assets/images/img-15.jpg" className="img-fluid mobile_media" alt="" />
                        </div>

                        <div className="media_slider_boder">
                            <div className="slider_bg2">
                                <Slider {...settings}>
                                    {Object.keys(fulldata).length > 0 && fulldata.media && fulldata.media.map((mediaitem, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="media_content">
                                                    <h5><span>{mediaitem.newspaper}</span> —  <span>{mediaitem.date}</span></h5>
                                                    <h2>{mediaitem.title}</h2>
                                                    <p>{mediaitem.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* <section id="launch">
                <div className="container">
                    <div className="about_width">
                        <div className="about_head">
                            <h5>Book Launch</h5>
                        </div>
                        <div className="booklaunh_detail">
                            <h4>{Object.keys(fulldata).length > 0 && fulldata.booklaunch.description}</h4>
                        </div>
                        <div className="box_container" >
                            {Object.keys(fulldata).length > 0 && fulldata.booklaunch.images && fulldata.booklaunch.images.map((imageitem, index) => {
                                return (
                                    <div className="box" key={index}>
                                        <img src={imageitem} alt="" onClick={() => {
                                            setShowGallery(true);
                                            // setShowIndex(index);
                                            setIndeex(index); $('body').css('overflow', 'hidden')
                                        }} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section> */}

      <section id="launch" className="launch_adding">
        <div className="container">
          <div className="about_width">
            <div className="about_head">
              <h5>From the authors</h5>
            </div>
            <div className="booklaunh_detail">
              {/* <h4>
                {Object.keys(fulldata).length > 0 &&
                  fulldata.fromAuthor.description}
              </h4> */}
            </div>

            <div className="box_container">
              {Object.keys(fulldata).length > 0 &&
                fulldata.fromAuthor.images &&
                fulldata.fromAuthor.images.map((imageitem, index) => {

                  return (
                    <div
                      className="box"
                      key={index}
                      onClick={() => {
                        setShowGallery(true);
                        // setShowIndex(index);
                        gtagEventForAuthVid(imageitem.name);
                        setIndeex(index);
                        setVideoObject(imageitem)
                        $("body").css("overflow", "hidden");
                      }}
                    >
                      {
                        imageitem.poster && <>
                          <img
                            className="authorImage"
                            src={imageitem.poster}
                            alt=""
                          // onClick={() => {
                          //     console.log('showgallery', showGallery)
                          //   setShowGallery(true);
                          //   // setShowIndex(index);
                          //   setIndeex(index);
                          //   $("body").css("overflow", "hidden");
                          // }}
                          />


                          <div className="box_overlay">
                            <img src="./assets/images/play.svg" alt="" />
                          </div>
                        </>
                      }
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>

      <section id="booklaunch" className="launch_adding2">
        <div className="container">
          <div className="about_width">
            <div className="about_head">
              <h5>Book Launch</h5>
            </div>
            <div className="booklaunh_detail">
              {/* <h4>
                {Object.keys(fulldata).length > 0 &&
                  fulldata.fromAuthor.description}
              </h4> */}
            </div>

            <div className="box_container">
              {Object.keys(fulldata).length > 0 &&
                fulldata.booklaunchimages.images &&
                fulldata.booklaunchimages.images.map((imageitem, index) => {

                  return (
                    <div
                      className="box"
                      key={index}
                      onClick={() => {
                       setShowImage(true);
                       setImageIndeex(index);
                       $("body").css("overflow", "hidden");
                        
                      }}
                    >
                      {
                        imageitem.poster && <>
                          <img
                            className="authorImage"
                            src={imageitem.poster}
                            alt=""
                          // onClick={() => {
                          //     console.log('showgallery', showGallery)
                          //   setShowImage(true);
                          //   // setShowIndex(index);
                          //   setIndeex(index);
                          //   $("body").css("overflow", "hidden");
                          // }}
                          />

                          {/* <div className="box_overlay">
                            <img src="./assets/images/play.svg" alt="" />
                          </div> */}
                        </>
                      }
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>

      <section id="buy" className="buynow_section">
        <img
          src="./assets/images/modibg.svg"
          className="img-fluid modi_text"
          alt=""
        />
        <div className="buynow_slider">
          <Slider {...buynow}>
            {Object.keys(fulldata).length > 0 &&
              fulldata.buynows.images &&
              fulldata.buynows.images.map((imageitem, index) => {
                return (
                  <div key={index}>
                    <div className="bunow_img">
                      <img src={imageitem} className="img-fluid" alt="" />
                    </div>
                  </div>
                );
              })}
          </Slider>

          <div className="buunow_btn">
            <div
              className="buynow_brd"
              onClick={() => {
                setBuyNoweEvent();
              }}
            >
              <a target="_blank" href="https://t.co/mgbsoU125t">
                {" "}
                <button id="bt">buy now</button>{" "}
              </a>
            </div>
          </div>
        </div>
      </section>
      {showGallery && (
        // <GalleryPopup
        //   items={fulldata.booklaunch.popupimages}
        //   imageindex={indeex}
        //   onClose={() => {
        //     setShowGallery(false);
        //     $("body").css("overflow", "auto");
        //   }}
        // />
        <GalleryPopup
          poster={videoObject.poster}
          videoUrl={videoObject.videoUrl}
          youtubeUrl={videoObject.youtube_url}
          imageindex={indeex}
          autoplay={showGallery}
          onReady={handlePlayerReady}
          items={fulldata.fromAuthor.popupVideos}
          onClose={() => {
            setShowGallery(false);
            gtagCloseAuth_vid(videoObject.name)
            $("body").css("overflow", "auto");
          }}
        />
      )}
      {showVideo && (
        <VideoPopUp
          items={"https://www.youtube.com/embed/RpF11Kqb4jU"}
          imageindex={indeex}
          onClose={() => {
            setShowVideo(false);
            $("body").css("overflow", "auto");
          }}
        />
      )}
      {showImage && (
        <ImagePopUp
          items={fulldata.booklaunchimages.images}
          imageindex={imageindeex}
          onClose={() => {
            setShowImage(false);
            $("body").css("overflow", "auto");
          }}
        />
      )}
    </>
  );
}

export default LandingPage;
