
import LandingPage from './Components/LandingPage';


function App() {
  return (
    <LandingPage />
  );
}

export default App;
